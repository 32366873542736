<template>
  <div v-frag>
    <hero-with-image :title="pageTitle"
                     subtitle="oufOfWarrantyRepairForm"
                     :image="imagePath"></hero-with-image>
    <template v-if="brand.mobilProviderId && brand.mobilProviderId === 1">
      <telekom-fizetos-form v-if="pageTitle !== 'Flip'"
                            :brand="brand"
                            :mobil-provider-id="mobilProviderId"
                            :mobil-provider-name="mobilProviderName">
      </telekom-fizetos-form>
      <flip-fizetos-form v-else
                         :brand="brand"
                         :mobil-provider-id="mobilProviderId"
                         :mobil-provider-name="mobilProviderName">
      </flip-fizetos-form>
    </template>
    <template v-else-if="brand.mobilProviderId && brand.mobilProviderId === 2">
      telenor fizetos
    </template>
    <template v-else-if="brand.mobilProviderId && brand.mobilProviderId === 3">
      <vodafone-fizetos-form :brand="brand" :mobil-provider-id="mobilProviderId" :mobil-provider-name="mobilProviderName"></vodafone-fizetos-form>
    </template>
    <template v-else>
      <div>
        a vodafone űrlap lenne itt, de azokat, még aktualizálni kell.
      </div>
    </template>
  </div>
</template>

<script>
import HeroWithImage from 'src/components/titles/HeroWithImage';
import FlipFizetosForm from 'src/components/forms/FlipFizetosForm.vue';
import TelekomFizetosForm from 'src/components/forms/TelekomFizetosForm.vue';
import VodafoneFizetosForm from 'src/components/forms/VodafoneFizetosForm.vue';

export default {
  name: 'MobilProviderFizetosPage',
  components: {
    HeroWithImage, FlipFizetosForm, TelekomFizetosForm, VodafoneFizetosForm,
  },
  props: {
    mobilProviderId: {
      type: Number,
      default: 999,
    },
    mobilProviderName: {
      type: String,
      default: 'EMPTY',
    },
  },
  async beforeMount() {
    if (this.$store.state.mobilProviderManagement.selectedBrand && this.mobilProviderId === this.$store.state.mobilProviderManagement.selectedBrand.mobilProviderId) {
      this.brand = this.$store.state.mobilProviderManagement.selectedBrand
    } else {
      this.brand = await this.getMobilProvider();
    }
    if (this.$route.name === 'flip-fizetos-javitas') {
      this.pageTitle = 'Flip';
      this.imagePath = 'data/crm/mobil_provider/flip640x640.png';
    } else {
      this.pageTitle = this.brand.mobilProviderName;
      this.imagePath = this.brand.imagePath;
    }
  },
  data() {
    return {
      brand: {},
      pageTitle: '',
      imagePath: '',
    };
  },
  methods: {
    async getMobilProvider() {
      // console.log('getMobilProvider ...');
      return await this.$store.dispatch('getMobilProviderById', this.mobilProviderId);
    },
  },
  watch: {
    //
  },
};
</script>

<style scoped>

</style>
